import axios, { AxiosResponse } from "axios";
import { IOrder } from "../types/order";

const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME || "https://lost-angel.ru"



interface IProduct {
    _id: string,
    name: string,
    description: string,
    price: number,
    imageUrl: string,
    folder: string,
    externalId: string
}

export async function getProductFolders(): Promise<Array<string>> {

    const result = await axios.get(`${DOMAIN_NAME}/api/shop/folders`)

    return result.data
}

export async function getProductsByFolder(folder: string): Promise<Array<IProduct>> {

    const result = await axios.get(`${DOMAIN_NAME}/api/shop/products?folder=${folder}`)
    return result.data
}

export async function getOneProduct(id: string): Promise<IProduct> {

    const result = await axios.get(`${DOMAIN_NAME}/api/shop/products/${id}`)



    return result.data
}

export async function getOrders(driverId: string): Promise<IOrder[]> {

    const result = await axios.get(`${DOMAIN_NAME}/api/shop/orders?driver=${driverId}`)



    return result.data
}

export async function getCurrentCart(driverId: string): Promise<IOrder> {

    const result = await axios.get(`${DOMAIN_NAME}/api/shop/carts?driver=${driverId}`)



    return result.data
}

export async function addItemToCart(driverId: string, productId: string): Promise<IOrder> {

    const data = {
        driver: driverId,
        productId
    }

    const result = await axios.post(`${DOMAIN_NAME}/api/shop/carts/add-item`, data)

    return result.data
}

export async function removeItemFromCart(driverId: string, itemId: string): Promise<IOrder> {

    const data = {
        driver: driverId,
        itemId
    }

    const result = await axios.post(`${DOMAIN_NAME}/api/shop/carts/remove-item`, data)

    return result.data
}

export async function purchaseCart(driverId: string): Promise<AxiosResponse<IOrder>> {
    const data = {
        driver: driverId
    }

    const result = await axios.post(`${DOMAIN_NAME}/api/shop/orders`, data)

    return result
}