import { useState, useEffect } from "react";
import { getProductsByFolder } from "../services/shop.ts";
import { Product } from "../types/product.ts"

function useProducts(filter: string) {
    const [products, setProducts] = useState<Product[]>();

    async function fetchProducts() {
        const products = await getProductsByFolder(filter)
        setProducts(products)
    }

    useEffect(() => {
        fetchProducts()
    }, [filter]);

    return products;
}


export default useProducts