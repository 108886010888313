import { useState, useEffect } from "react";
import { getDriverProfile } from "../services/driver.ts";
import { DriverProfile } from "../types/driverProfile.ts";
import { IOrder } from "../types/order.ts";
import { getCurrentCart } from "../services/shop.ts";

function useCart(driverId: string) {
    const [cart, setCart] = useState<IOrder>();

    async function fetchCart() {
        const cart = await getCurrentCart(driverId)
        setCart(cart)
    }

    useEffect(() => {
        fetchCart()
    }, []);

    return cart;
}


export default useCart