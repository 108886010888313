import React from "react";
import { IOrder } from "../../types/order.ts"

export function Order(props: { order: IOrder }) {

    let statusColor: string

    switch (props.order.status) {
        case 'pending':
            statusColor = "#FFEA7E"
            break
        case 'completed':
            statusColor = "#00FFB2"
            break
        case 'cancelled':
            statusColor = "#990000"
            break
        default:
            statusColor = "#FFEA7E"
            break
    }

    const cardStyle: React.CSSProperties = {
        backgroundColor: '#191919',
        borderRadius: '15px',
        marginTop: '15px',

        width: 'calc(100% - 30px)',
        color: 'white',
        padding: '10px 15px',
    }

    const statusStyle: React.CSSProperties = {
        backgroundColor: '#191919',
        borderRadius: '15px',


        fontFamily: 'Inter, sans-serif',
        fontSize: '20px',
        fontWeight: '500',

        color: statusColor
    }

    const orderNoStyle: React.CSSProperties = {
        marginTop: '1px',
        fontWeight: '300',
        fontSize: '11px'
    }

    const imgCarousel: React.CSSProperties = {
        paddingTop: '10px',
        paddingBottom: '5px',
        display: 'flex',
        gap: '10px'
    }

    const imgStyle: React.CSSProperties = {
        width: '64px',
        height: '64px',
        borderRadius: '2px'
    }

    console.log(statusColor)


    return (
        <div style={cardStyle}>
            <div style={statusStyle}>В обработке</div>
            <div style={orderNoStyle}>Заказ №{props.order._id}</div>
            <div style={imgCarousel}>
                {props.order.items.map((item, index) => {
                    return (<img style={imgStyle} src={item.product.imageUrl}></img>)
                })}
            </div>

        </div>
    )
}