import { useState, useEffect } from "react";
import { IOrder } from "../types/order.ts";
import { getOrders } from "../services/shop.ts";


function useOrders(driverId: string) {
    const [orders, setOrders] = useState<IOrder[]>();

    async function fetchOrders() {
        const orders = await getOrders(driverId)
        setOrders(orders)
    }

    useEffect(() => {
        fetchOrders()
    }, []);

    return orders;
}


export default useOrders