import React, { useEffect } from "react";
import ProfileCard from "./ProfileCard.tsx";
import BalanceContainer from "./BalanceContainer.tsx";
import PersonalStats from "./PersonalStats.tsx";
import ShopPromo from "./ShopPromo.tsx";
import Leaderboards from "./Leaderboards.tsx";
import NavBar, { State } from "../navigation/NavBar.tsx";
import { useDriver } from "../../providers/DriverProvider.tsx";
import useDriverProfile from "../../hooks/useDriverProfile.tsx";
import useActualDriverProfile from "../../hooks/useActualDriverProfile.tsx";

function Profile() {

    const { driverId } = useDriver()

    const driverProfile = useDriverProfile(driverId)


    console.log(driverId)

    const containerStyle: React.CSSProperties = {
        backgroundColor: 'black',
        height: `calc(100vh - ${40}px)`,
        padding: `${40}px 40px`,
        overflowY: 'auto',
    }

    if (!driverProfile) {
        return
    }

    return (
        <div style={containerStyle}>
            <ProfileCard driver={driverProfile}></ProfileCard>
            <BalanceContainer driver={driverProfile}></BalanceContainer>
            <PersonalStats driverId={driverId}></PersonalStats>
            <ShopPromo></ShopPromo>
            <Leaderboards></Leaderboards>
            <NavBar state={"HOME"} />
        </div>
    )
}

export default Profile;
