import React, { useEffect, useState } from "react";
import ItemAmountPanel from "./ItemAmountPanel.tsx";
import { formatBalance, formatPriceFromMoySklad } from "../../utils/formatPrice.ts";
import { ICartItem } from "../../types/order.ts";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { removeItemFromCart } from "../../services/shop.ts";



function CartItem(props: { cartItem: ICartItem, onRemove: (id: string) => void }) {

    const [visible, setVisible] = useState(true)

    const cardStyle: React.CSSProperties = {
        display: 'flex',
        marginTop: '15px',
        color: 'white',
        position: "relative"
    }

    const image: React.CSSProperties = {
        objectFit: "cover",
        width: '100px',
        height: '100px',
        borderRadius: '15px',
        flexShrink: '0'
    }

    const textContainer: React.CSSProperties = {
        marginLeft: '20px',
        flexGrow: '1',
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: '300',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0',


    }

    const nameStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }

    const folderStyle: React.CSSProperties = {
        fontSize: '14px',
        fontWeight: '500',
        color: "#B1B1B1"
    }

    const priceAndQuantity: React.CSSProperties = {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Rubik, sans-serif',
        fontSize: '20px',
        fontWeight: '600'
    }

    const closeIconStyle: React.CSSProperties = {
        // position: 'absolute',
        // top: '0',
        // right: '0',
        flexShrink: '0'

    };

    if (!visible)
        return



    return (

        <div style={cardStyle}>
            <img style={image} src={props.cartItem.product.imageUrl} alt={"image"} />
            <div style={textContainer}>
                <div style={nameStyle}>
                    <div>{props.cartItem.product.name}</div>

                    <IoIosClose style={closeIconStyle} size={'30px'} onClick={() => { props.onRemove(props.cartItem._id); }} />
                </div>
                <div style={folderStyle}>{props.cartItem.product.folder}</div>
                <div style={priceAndQuantity}>
                    <div>{formatBalance(props.cartItem.product.price)}</div>

                </div>

            </div>

        </div>
    );
}

export default CartItem;
