import React, { useEffect, useState } from 'react';
import { Heading } from '../common/Heading.js';
import { BsCart2 } from "react-icons/bs";
import NavBar, { State } from '../navigation/NavBar.tsx';
import { useNavigate } from 'react-router-dom';
import useCart from '../../hooks/useCart.tsx';
import { useDriver } from "../../providers/DriverProvider.tsx";
import CartItem from './CartItem.tsx';
import { purchaseCart, removeItemFromCart } from '../../services/shop.ts';
import { ICartItem, IOrder } from '../../types/order.ts';
import { formatBalance } from '../../utils/formatPrice.ts';
import { ClipLoader } from 'react-spinners';



function Cart() {

    const { driverId } = useDriver()

    const cart = useCart(driverId)

    const [cartItems, setCartItems] = useState<ICartItem[]>([])

    const [totalAmount, setTotalAmount] = useState(0)

    const [purchaseStatus, setPurchaseStatus] = useState<'default' | 'loading' | 'success'>('default');



    useEffect(() => {
        if (cart?.items) {
            setCartItems(cart.items)
        }
        if (cart?.totalAmount) {
            setTotalAmount(cart.totalAmount)
        }
    }, [cart])

    useEffect(() => {


    }, [cartItems]);

    const deleteCartItem = async (itemId: string) => {
        const updatedCart = cartItems
            .filter(item => item._id !== itemId);


        setCartItems(updatedCart);

        const newCart = await removeItemFromCart(driverId, itemId)
        setTotalAmount(newCart.totalAmount)

    };

    const handlePurchase = async () => {
        setPurchaseStatus('loading');

        try {
            const response = await purchaseCart(driverId);
            if (response.status === 200) {
                setPurchaseStatus('success');
                setTimeout(() => {
                    navigate('/orders');  // Navigate to orders after delay
                }, 1500);  // 1.5-second delay for transition
            } else {
                setPurchaseStatus('default');
                alert("Недостаточно средств на балансе");
            }
        } catch (error) {
            setPurchaseStatus('default');
            alert("Ошибка при совершении покупки");
        }
    };




    const navigate = useNavigate()

    const pageStyle: React.CSSProperties = {
        backgroundColor: 'black',
        height: `calc(100vh - ${40}px)`,
        padding: `${40}px 40px`,
        overflowY: 'auto',
    };

    const emptyPage: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        paddingTop: '100px'
    }

    const backToShopButton: React.CSSProperties = {
        width: '75%',
        height: '60px',
        borderRadius: '40px',
        backgroundColor: "#000000",
        color: "#DD5C00",
        display: 'flex',
        border: '2px solid #DD5C00',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '18px',
        marginTop: '10px'
    }

    const purchaseButton: React.CSSProperties = {
        position: "fixed",
        bottom: "100px",
        width: '75%',
        height: '60px',
        borderRadius: '40px',
        backgroundColor: purchaseStatus === 'success' ? "#28a745" : "#DD5C00",
        color: purchaseStatus === 'success' ? "white" : "white",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '18px',
        marginTop: '10px',
        transition: 'background-color 0.5s ease', // Smooth transition for color change

    }


    const renderCartItems = () => (
        <>
            {cartItems.map((item, index) => (
                <CartItem cartItem={item} onRemove={deleteCartItem}></CartItem>
            ))}
            <div style={{ height: `${80}px` }}></div>
            {/* <div style={purchaseButton}>Оформить за {formatBalance(totalAmount || 0)}</div> */}
            <div style={purchaseButton} onClick={handlePurchase}>
                {purchaseStatus === 'loading' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ClipLoader color="#ffffff" size={16} />
                        <span style={{ marginLeft: '8px' }}>Загрузка...</span>
                    </div>
                ) : purchaseStatus === 'success' ? (
                    <span>Успешно</span>
                ) : (
                    <>Оформить за {formatBalance(totalAmount || 0)}</>
                )}
            </div>
        </>
    )

    const emptyCart = () => (
        <div style={emptyPage}>
            <BsCart2 size={160} color='353535'></BsCart2>

            <p style={{ fontSize: "20px", marginTop: '40px' }}>Ваша корзина пуста</p>
            <div style={backToShopButton} onClick={() => { navigate("/shop") }}>Начать покупки</div>
        </div>
    )



    return (
        <div style={pageStyle}>
            <Heading name="Корзина" />
            {
                (totalAmount === 0) ? emptyCart() : renderCartItems()
            }
            <NavBar state="SHOP"></NavBar>
        </div>
    );
}

export default Cart;
