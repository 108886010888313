export function formatPrice(price: number) {
    const intPrice = price / 100
    const formattedNumber = new Intl.NumberFormat('en-US').format(intPrice); // '1,000,000'
    const formattedWithSpaces = formattedNumber.replace(/,/g, ' '); // '1 000 000'

    return formattedWithSpaces
}

export function formatBalance(value: number | string): string {
    value = Number(value)
    const fixedValue = value.toFixed(2);

    const [integerPart, decimalPart] = fixedValue.split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return `${formattedIntegerPart},${decimalPart} ₸`;

}

export function formatPriceFromMoySklad(price: number) {
    const intPrice = price
    const formattedNumber = new Intl.NumberFormat('en-US').format(intPrice); // '1,000,000'
    const formattedWithSpaces = formattedNumber.replace(/,/g, ' '); // '1 000 000'

    return formattedWithSpaces
}