import React from "react";
import { FaHome } from "react-icons/fa";
import { MdLeaderboard } from "react-icons/md";
import { FaShop } from "react-icons/fa6";
import { LuClipboardList } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

export type State = "HOME" | "LEADERBOARDS" | "SHOP" | "ORDERS"

const HEIGHT = 80
const PADDING = 0
export const NAVBAR_HEIGHT = HEIGHT + 2 * PADDING

function NavBar(props: { state: State }) {

    const navigate = useNavigate()

    const navBarPadding = 40

    const navBarContainer: React.CSSProperties = {
        position: "fixed",
        bottom: "0",
        left: "0",
        padding: `${PADDING}px ${navBarPadding}px`,
        height: `${HEIGHT}px`,
        width: `calc(100vw - ${2 * navBarPadding}px)`,
        display: "flex",
        backgroundColor: "#303030",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
        color: "#FFFFFF",
        zIndex: "100",
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px'
    }

    const navButtonContainer: React.CSSProperties = {
        width: "55px",
        height: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    }

    const activeNavButtonContainer: React.CSSProperties = {
        ...navButtonContainer,
        color: "#FF8A00",
    }

    function buttonStateByPage(state: State) {
        return (props.state === state) ? activeNavButtonContainer : navButtonContainer
    }


    return (
        <div>
            <div style={{ height: `${NAVBAR_HEIGHT + 20}px` }}></div>
            <div style={navBarContainer}>
                <div style={buttonStateByPage("HOME")} onClick={() => navigate("/home")}>
                    <FaHome size={35}></FaHome>
                    <div>Главная</div>
                </div>
                <div style={buttonStateByPage("LEADERBOARDS")} onClick={() => navigate("/leaderboard")}>
                    <MdLeaderboard size={35}></MdLeaderboard>
                    <div>Рейтинг</div>
                </div>
                <div style={buttonStateByPage("SHOP")} onClick={() => navigate("/shop")}>
                    <FaShop size={35}></FaShop>
                    <div>Магазин</div>
                </div>
                <div style={buttonStateByPage("ORDERS")} onClick={() => navigate("/orders")}>
                    <LuClipboardList size={35}></LuClipboardList>
                    <div>Заказы</div>
                </div>
            </div>
        </div>
    )
}


export default NavBar