import React from "react";
import Avatar from 'react-avatar';
import { formatBalance } from "../../utils/formatPrice.ts";



const cardStyle: React.CSSProperties = {
    backgroundColor: '#191919',
    color: 'white',
    padding: '12px',
    borderRadius: '10px',
    width: 'calc(100% - 24px)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
}

const avatarStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',

    width: '40px',
    height: '40px',
    borderRadius: '20px',
    marginRight: '16px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'teal',
}

const driverProfile: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
}

const bold: React.CSSProperties = {
    fontWeight: '500'
}

const money: React.CSSProperties = {
    marginLeft: 'auto',
    fontFamily: 'Inter, sans-serif',
    fontSize: '22px',
    fontWeight: '300'
}

interface DriverProfile {
    name: string;
    last_name: string;
    car: string;
    balance: number;
}

function LeaderboardElement({ name, last_name, car, balance }: DriverProfile) {



    return (


        <div style={cardStyle}>

            {/* <img style={avatarStyle} src="https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-the-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1725408000&semt=ais_hybrid"></img> */}

            <Avatar style={avatarStyle}
                name={`${name} ${last_name}`}
                size="50"
                round={true}
                color="#D9D9D9"
                fgColor="#000000"
            />


            <div style={driverProfile}>
                <div style={bold}>{name}</div>
                <div>{car}</div>
            </div>
            <div style={money}>{formatBalance(balance)}</div>

        </div>

    )
}

export default LeaderboardElement