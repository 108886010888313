import React from "react";
import { FaMoneyBill } from 'react-icons/fa';
import { PiWallet } from "react-icons/pi";
import { formatBalance } from "../../utils/formatPrice.ts"
import usePersonalStats from "../../hooks/usePersonalStats.tsx";


const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',
    padding: '30px 0',
    display: 'flex',
    gap: '10px'

}

const cardStyle: React.CSSProperties = {
    backgroundColor: '#191919',
    color: 'white',
    padding: '16px 16px 4px 16px',
    borderRadius: '10px',
    width: '55%'

}

const iconContainer: React.CSSProperties = {
    backgroundColor: '#353535',
    color: 'white',
    padding: '6px',
    paddingBottom: '3px',
    borderRadius: '4px',
    display: 'inline-block',
    marginBottom: '8px'
}



const zarabotano: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '700',
    fontSize: '13px',
    marginBottom: '4px'
}

const zaPosNed: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '300',
    fontSize: '13px',
    color: '#B1B1B1',
    marginBottom: '4px'
}

const amount: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '100',
    fontSize: '28px',
    color: '#17B909',
    marginBottom: '4px'
}

const totalsContainer: React.CSSProperties = {
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '100',
    fontSize: '15px',
    color: '#17B909',
    marginBottom: '4px',
}

const negativeStyle: React.CSSProperties = {
    color: '#CF4646',

}

function PersonalStats(props: { driverId: string }) {

    const dailyStats = usePersonalStats(props.driverId, "DAY")
    const weeklyStats = usePersonalStats(props.driverId, "WEEK")

    if (!dailyStats && !weeklyStats) {
        return
    }

    return (
        <div style={containerStyle}>

            <div style={cardStyle}>
                <div style={iconContainer}>
                    <FaMoneyBill size={24} color="white" />
                </div>
                <div style={zarabotano}>ЗАРАБОТАНО</div>
                <div style={zaPosNed}>За сегодня</div>
                <div style={amount}>+{formatBalance(dailyStats?.totalIncomes || 0)}</div>
            </div>

            <div style={cardStyle}>
                <div style={iconContainer}>
                    <PiWallet size={24} color="white" />
                </div>
                <div style={zarabotano}>ВСЕ ОПЕРАЦИИ</div>
                <div style={zaPosNed}>За неделю</div>
                <div style={totalsContainer}>
                    <div>+{formatBalance(weeklyStats?.totalIncomes || 0)}</div>
                    <div style={negativeStyle}>{formatBalance(weeklyStats?.totalExpenses || 0)}</div>
                </div>
            </div>

        </div>
    )
}

export default PersonalStats