import React from "react";
import { formatPriceFromMoySklad } from "../../../utils/formatPrice.ts"
import { useNavigate } from "react-router-dom";

export function Item({
    item
}) {

    const navigate = useNavigate();

    function handleClick() {
        navigate(`/product/${item._id}`)
    }

    const container: React.CSSProperties = {
        //width: '45vw',
        flex: '1 1 calc(40% - 10px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        margin: '0',
        boxSizing: 'border-box',
        textDecoration: 'none'
    }
    const imageStyle: React.CSSProperties = {
        height: '140px',
        width: '100%',
        borderRadius: '8px',
        objectFit: 'cover',
        marginBottom: '8px',
    }
    const nameStyle: React.CSSProperties = {
        fontFamily: 'Inter, sans-serif', // Применение шрифта Inter
        fontSize: '11px', // Размер шрифта 11px
        fontWeight: '500',
        color: '#B1B1B1', // Цвет текста #B1B1B1
        marginLeft: '14px',
        marginBottom: '0',
        marginTop: '8px',
        width: '100%'
    }
    const priceStyle: React.CSSProperties = {
        fontFamily: 'Rubik, sans-serif', // Шрифт Rubik для цены
        fontSize: '20px',
        fontWeight: '700',
        color: '#ffffff',
        marginLeft: '14px',
        marginBottom: '8px',
        marginTop: '8px',
    }




    return (

        <div onClick={() => { handleClick() }}>

            <img src={item.imageUrl} alt={item.name} style={imageStyle} />
            <h3 style={nameStyle}>{item.name}</h3>
            <span style={priceStyle}>{formatPriceFromMoySklad(item.price)}</span>
        </div>

    );
}