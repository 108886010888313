import { useState, useEffect } from "react";
import { getOneProduct } from "../services/shop.ts";
import { Product } from "../types/product.ts"

function useProduct(productId: string) {

    const [product, setProduct] = useState<Product>();

    async function fetchProduct() {
        const product = await getOneProduct(productId)
        setProduct(product)
    }

    useEffect(() => {
        fetchProduct()
    }, [productId]);

    return product;
}


export default useProduct