export function formatDate(date: Date) {
    const day = date.toISOString().slice(0, 10)
    return day

}

function getIntervalByPeriod(days: number) {
    const to = new Date().toISOString().slice(0, 10)
    const fromDate = new Date()
    fromDate.setDate(new Date().getDate() - days)
    const from = fromDate.toISOString().slice(0, 10)
    return { to, from }
}

export function getIntervalByName(period: "DAY" | "WEEK" | "MONTH") {
    switch (period) {
        case "DAY":
            return getIntervalByPeriod(0)
        case "WEEK":
            return getIntervalByPeriod(6)
        case "MONTH":
            return getIntervalByPeriod(30)
        default:
            return getIntervalByPeriod(0)
    }
}