import React, { useEffect, useState } from "react";
import { Product } from "../../types/product";
import { Heading } from "../common/Heading";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import useProduct from "../../hooks/useProduct.tsx";
import { useDriver } from "../../providers/DriverProvider.tsx";
import { addItemToCart } from "../../services/shop.ts";
import useCart from "../../hooks/useCart.tsx";
import { AiOutlineInfoCircle } from "react-icons/ai"; // Importing info icon



export function ProductDetails(props: { product: Product }) {

    const [inCart, setInCart] = useState(false);

    let { productId } = useParams()
    if (!productId) {
        productId = ''
    }

    const { driverId } = useDriver()
    const product = useProduct(productId)
    const cart = useCart(driverId)

    useEffect(() => {
        console.log('check')
        const checkProductInCart = async () => {
            try {
                if (!cart) {
                    setInCart(false)
                    console.log('no cart')
                } else if (cart.items.some(item => item.product.externalId === productId)) {
                    console.log('contained')
                    setInCart(true);
                }
            } catch (error) {
                console.error("Failed to check if product is in cart", error);
            }
        };

        if (productId) {
            checkProductInCart();
        }
    }, [cart]);


    const navigate = useNavigate()
    const padding = 40

    const pageContainer: React.CSSProperties = {
        backgroundColor: 'black',
        height: `calc(100vh - ${padding}px)`,
        padding: `${padding}px 40px`,
        overflowY: 'auto',

        color: 'white'
    }

    const imgStyle: React.CSSProperties = {
        width: 'calc(100vw - 80px)',
        backgroundColor: 'white',
        height: '250px',
        borderRadius: '15px',
        marginTop: '15px',
        objectFit: 'cover'
    }

    const nameStyle: React.CSSProperties = {
        fontFamily: 'Inter, sans-serif',
        fontSize: '20px',
        marginTop: '20px'
    }

    const descriptionStyle: React.CSSProperties = {
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        color: '#B1B1B1',
        marginTop: '10px'

    }

    const priceStyle: React.CSSProperties = {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '28px',
        fontWeight: '600',
        marginTop: '10px'

    }

    const infoTextStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        color: '#00BFFF', // Blue color for text and icon
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        marginTop: '10px'
    };


    const buttonStyle: React.CSSProperties = {

        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        borderRadius: '30px',
        border: '2px solid #FF8A00',
        // color: '#FF8A00',
        color: inCart ? 'white' : '#FF8A00',
        marginTop: '100px',


        position: "fixed",
        bottom: "40px",
        left: "40px",

        width: 'calc(100vw - 80px)',
        backgroundColor: inCart ? '#FF8A00' : 'transparent',
        transition: 'background-color 0.3s, color 0.3s'

    }

    const inCartButtonStyle: React.CSSProperties = {

        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        borderRadius: '30px',
        border: '2px solid #FF8A00',
        // color: '#FF8A00',
        color: inCart ? 'white' : '#FF8A00',
        marginTop: '100px',


        position: "fixed",
        bottom: "40px",
        left: "40px",

        width: 'calc(100vw - 80px)',
        backgroundColor: inCart ? '#FF8A00' : 'transparent',


    }

    const handleAddToCart = async () => {
        try {

            await addItemToCart(driverId, productId)

            setInCart(true);

        } catch (error) {
            console.error("Failed to add to cart", error);
        }
    };


    return (
        <div style={pageContainer}>

            <IoIosArrowBack size={35} onClick={() => { navigate(`/shop?filter=${product?.folder}`) }} />

            <img style={imgStyle} src={product?.imageUrl}></img>
            <div style={nameStyle}>{product?.name}</div>
            <div style={priceStyle}>{product?.price} ₸</div>
            <div style={descriptionStyle}>{product?.description || "Здесь должно быть описание товара"}</div>
            <div style={infoTextStyle}>
                <AiOutlineInfoCircle size='40px' style={{ marginRight: '15px' }} />
                Товар можно забрать по адресу Толе би 288а, магазин ShaitanArba
            </div>
            {inCart ?
                (<div style={inCartButtonStyle} onClick={() => { navigate("/cart") }}>В корзине</div>) :
                (<div style={buttonStyle} onClick={handleAddToCart}>Добавить в корзину</div>)
            }

        </div>
    )
}