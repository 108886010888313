import React from "react";
import mopedImage from '../../static/img/image 8.png'
import { useNavigate } from "react-router-dom";

const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',
}

const cardStyle: React.CSSProperties = {
    background: 'linear-gradient(90deg, #ff8a00, #990000)',
    color: 'white',
    padding: '16px',
    borderRadius: '10px',
    position: 'relative'
}

const trebuetsya: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '700',
    fontSize: '16px',
    marginBottom: '4px'
}

const desc: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '100',
    fontSize: '12px',
    width: '70%',
    marginBottom: '4px'
}

const imgStyle: React.CSSProperties = {
    position: 'absolute',
    right: '10px',
    top: '-30px',
    zIndex: 1,
    width: '25%',
    height: 'auto'
};

function ShopPromo() {

    const navigate = useNavigate()

    const handleImageClick = () => {
        navigate("/shop")
    };

    return (
        <div style={containerStyle}
            onClick={handleImageClick}>

            <div style={cardStyle}>

                <div style={trebuetsya}>Требуется ремонт?</div>
                <div
                    style={desc}

                >Закажи необходимые детали за средства своего баланса без комиссии!</div>
                <img
                    style={imgStyle}
                    src={mopedImage} // Замените на ваше изображение
                    alt="icon"
                    onClick={handleImageClick}
                />
            </div>
        </div>
    )
}

export default ShopPromo