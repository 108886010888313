import React from "react";
import Avatar from 'react-avatar';
import useDriverProfile from "../../hooks/useDriverProfile.tsx";
import useTelegram from "../../hooks/useTelegram.tsx";
import { DriverProfile } from "../../types/driverProfile.ts";


const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'left'
}

const avatarStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',

    width: '40px',
    height: '40px',
    borderRadius: '20px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'teal',
    fontSize: '14px',

}

const textContainerStyle: React.CSSProperties = {
    marginLeft: '16px'
}

const welcomeTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '100',
    fontSize: '16px'
}

const nameStyle: React.CSSProperties = {
    ...welcomeTextStyle,
    fontWeight: '600'
}

function ProfileCard(props: { driver: DriverProfile }) {


    let name: string
    let avatarUrl: string | undefined

    if (!props.driver.driver_profile.first_name) {
        name = "Test Name"
    } else {
        name = props.driver.driver_profile.first_name
    }

    return (
        <div style={containerStyle}>
            {!avatarUrl ? (
                <Avatar style={avatarStyle}
                    name={name}
                    size="50"
                    round={true}
                    color="#D9D9D9"
                    fgColor="#000000"
                />
            ) : (<img style={avatarStyle} src={avatarUrl} alt={"kek"}></img>)
            }

            <div style={textContainerStyle}>
                <div style={welcomeTextStyle}>Добро пожаловать</div>
                <div style={nameStyle}>{name}</div>
            </div>

        </div>
    )
}

export default ProfileCard