import { useState, useEffect } from "react";
import { Leaderboard } from "../types/leaderboard";
import { getLeaderboard } from "../services/driver.ts";

function useLeaderboard() {
    const [leaderboard, setLeaderboard] = useState<Leaderboard>();

    async function fetchLeaderboard() {
        const leaderboard = await getLeaderboard("DAY")
        setLeaderboard(leaderboard)
    }

    useEffect(() => {
        fetchLeaderboard()
    }, []);

    return leaderboard;
}


export default useLeaderboard