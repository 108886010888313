import { ItemList } from './item-list/ItemList.tsx';
import { FilterList } from './filter/FilterList.tsx';
import { Heading } from '../common/Heading.js';
import { useState } from 'react';
import React from 'react';
import { FaShoppingCart } from "react-icons/fa";
import NavBar from '../navigation/NavBar.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import useCart from '../../hooks/useCart.tsx';
import { useDriver } from "../../providers/DriverProvider.tsx";



function Shop() {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    let filter = queryParams.get('filter');

    const { driverId } = useDriver()

    const cart = useCart(driverId)

    if (!filter) {
        filter = "АКСЕССУАРЫ"
    }

    const navigate = useNavigate()


    const [activeFilter, setActiveFilter] = useState<string>(filter)

    const handleFilterChange = (filter: string) => {
        setActiveFilter(filter)
        // но здесь не запускается запрос на /products
    }

    const mainPage: React.CSSProperties = {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'black',
        height: `calc(100vh - ${40}px)`,
        padding: `${40}px 40px`,
        overflowY: 'auto',
    }

    const headingContainer: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        position: 'relative'

    }

    const cartIconContainer: React.CSSProperties = {
        position: 'relative',
        cursor: 'pointer',
    };

    const badgeStyle: React.CSSProperties = {
        position: 'absolute',
        top: '-5px',
        right: '-10px',
        backgroundColor: 'yellow',
        color: 'black',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 'bold'
    };



    return (
        <div style={mainPage}>
            <div style={headingContainer}>
                <Heading name={'Магазин'} />
                <div style={cartIconContainer} onClick={() => { navigate("/cart") }}>
                    <FaShoppingCart size={35} onClick={() => { navigate("/cart") }}></FaShoppingCart>
                    {(cart && cart?.items.length > 0) && (
                        <div style={badgeStyle}>{cart?.items.length}</div>
                    )}
                </div>

            </div>
            <FilterList
                onFilterChange={handleFilterChange}
                activeFilter={activeFilter}
            />
            <ItemList
                activeFilter={activeFilter}
            />

            <NavBar state="SHOP"></NavBar>

        </div>
    );
}

export default Shop;
