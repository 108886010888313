import React from "react";
import { Heading } from "../common/Heading";
import useOrders from "../../hooks/useOrders.tsx";
import { useDriver } from "../../providers/DriverProvider.tsx";
import { Order } from "./Order.tsx";
import { LuClipboardList } from "react-icons/lu";

import NavBar from "../navigation/NavBar.tsx";
import { useNavigate } from "react-router-dom";

export function OrderList() {

    const navigate = useNavigate()

    const pageStyle: React.CSSProperties = {
        backgroundColor: 'black',
        height: `calc(100vh - ${40}px)`,
        padding: `${40}px 40px`,
        overflowY: 'auto',
    }

    const emptyPage: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        paddingTop: '100px'
    }

    const backToShopButton: React.CSSProperties = {
        width: '75%',
        height: '60px',
        borderRadius: '40px',
        backgroundColor: "#000000",
        color: "#DD5C00",
        display: 'flex',
        border: '2px solid #DD5C00',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '18px',
        marginTop: '10px'
    }

    const { driverId } = useDriver()

    let orders = useOrders(driverId)

    if (!orders) {
        orders = []
    }

    return (
        <div style={pageStyle}>
            <Heading name='Заказы'></Heading>
            {(orders.length === 0) ? (
                <div style={emptyPage}>
                    <LuClipboardList size={160} color='353535'></LuClipboardList>

                    <p style={{ fontSize: "20px", marginTop: '40px' }}>У вас пока нет заказов</p>
                    <div style={backToShopButton} onClick={() => { navigate("/shop") }}>Начать покупки</div>
                </div>
            ) :

                orders.map((item, index) => {
                    return <Order order={item}></Order>
                })
            }
            { }
            <NavBar state={"ORDERS"} />

        </div >
    )
}