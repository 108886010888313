import React from "react";
import { SlArrowRight } from "react-icons/sl";
import LeaderboardElement from "../leaderboard/LeaderboardElement.tsx";
import useLeaderboard from "../../hooks/useLeaderboard.tsx";
import { useNavigate } from "react-router-dom";

const containerStyle: React.CSSProperties = {
    backgroundColor: 'black',
    color: 'white',
    paddingTop: '20px',
}

const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
}

const staticTextStyle: React.CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '300',
    fontSize: '24px',
}

const moreButton: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '300',
    color: '#B1B1B1',
    fontSize: '12px',
    gap: '4px'
}

const arrowIcon: React.CSSProperties = {
    height: '10px'
}

function Leaderboards() {

    const leaderboard = useLeaderboard()
    const navigate = useNavigate()

    if (!leaderboard) {
        return
    }

    const handleDetailsClick = () => {
        navigate("/leaderboard")
    };


    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <div style={staticTextStyle}>Топ 3 за сегодня</div>
                <div onClick={handleDetailsClick} style={moreButton}>
                    <div>Подробнее</div>
                    <SlArrowRight style={arrowIcon} />
                </div>

            </div>
            {leaderboard.map((element) => (
                <LeaderboardElement
                    name={element.driver.driver_profile.first_name}
                    car={element.driver.car.model}
                    balance={element.totalIncomes}
                    last_name={element.driver.driver_profile.last_name}
                ></LeaderboardElement>
            )).slice(0, 3)}
        </div>
    )
}

export default Leaderboards