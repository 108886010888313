import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';

import "./index.css"

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import { ProductDetails } from './components/product-details/ProductDetails.tsx';
import Profile from './components/profile/Profile.tsx';
import Leaderboards from './components/leaderboard/Leaderboard.tsx';
import Shop from './components/shop/Shop.tsx';
import { OrderList } from './components/orders/OrderList.tsx';
import { PhoneProvider } from './providers/PhoneProvider.tsx';
import { DriverProvider } from './providers/DriverProvider.tsx';
import Cart from './components/cart/Cart.tsx';


const router = createBrowserRouter([
  {
    path: "/",
    element: <DriverProvider>
      <Profile />
    </DriverProvider>
  },
  {
    path: "/home",
    element: <DriverProvider>
      <Profile />
    </DriverProvider>
  },
  {
    path: "/leaderboard",
    element: <Leaderboards />,
  },
  {
    path: "/shop",
    element: <DriverProvider><Shop /></DriverProvider>
  },
  {
    path: "/orders",
    element: <DriverProvider><OrderList /></DriverProvider>
  },
  {
    path: "/cart",
    element: <DriverProvider><Cart /></DriverProvider>
  },

  {
    path: "/product/:productId",
    element: <DriverProvider><ProductDetails /></DriverProvider>
  }
]);


createRoot(document.getElementById("root")).render(



  <RouterProvider router={router} />


);

