import { useState, useEffect } from "react";
import { getProductFolders } from "../services/shop.ts";

function useFilters() {
    const [filters, setFilters] = useState<string[]>([]);

    async function fetchFilters() {
        const filterList = await getProductFolders()
        setFilters(filterList)
    }

    useEffect(() => {
        fetchFilters()
    }, []);

    return filters;
}


export default useFilters