import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DriverContext = createContext({ driverId: '' });

export const useDriver = () => useContext(DriverContext);

export const DriverProvider = ({ children }) => {
    const location = useLocation();

    const [driver, setDriver] = useState(() => {

        const queryParams = new URLSearchParams(location.search);
        let driverId = queryParams.get('driverId');

        if (driverId) {

            localStorage.setItem('driverId', driverId);
        }
        else {
            driverId = localStorage.getItem('driverId') || '';
        }



        return driverId || ""
    });

    useEffect(() => {

        if (!driver) {
            const queryParams = new URLSearchParams(location.search);
            const driverFromQuery = queryParams.get('driverId');

            if (driverFromQuery) {
                setDriver(driverFromQuery);
                localStorage.setItem('driverId', driverFromQuery);
            }
        }
    }, [location.search, driver]);

    return (
        <DriverContext.Provider value={{ driverId: driver }}>
            {children}
        </DriverContext.Provider>
    );
};
