import { useState, useEffect } from "react";
import { PersonalStats } from "../types/personalStats.ts";
import { getPersonalStats } from "../services/driver.ts";


function usePersonalStats(driverId: string, period: "DAY" | "WEEK") {
    const [stats, setStats] = useState<PersonalStats>();

    async function fetchPersonalStats() {
        const personalStats = await getPersonalStats(driverId, period)
        setStats(personalStats)
    }

    useEffect(() => {
        fetchPersonalStats()
    }, []);

    return stats;
}


export default usePersonalStats