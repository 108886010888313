import useProducts from "../../../hooks/useProducts.tsx"
import { Item } from "./Item.tsx"
import React from "react"

export function ItemList({ activeFilter }) {

    const products = useProducts(activeFilter)

    const container: React.CSSProperties = {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'space-between',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexGrow: 1,
        scrollbarWidth: 'none',
    }
    const itemStyle: React.CSSProperties = {
        width: 'calc(50% - 10px)',
        flexGrow: 0,
        flexShrink: 0,
        boxSizing: 'border-box',
    }

    if (!products) {
        return
    }


    return (
        <div style={container} >

            {
                products.map((item, index) => (
                    <div style={itemStyle} key={index} >
                        <Item item={item} />
                    </div>
                ))
            }

        </div>
    )
}
