import React, { useState } from "react";
import LeaderboardElement from "./LeaderboardElement.tsx";
import { Heading } from "../common/Heading.js";
import { DriverProfile } from "../../types/driverProfile.ts";
import useLeaderboard from "../../hooks/useLeaderboard.tsx";
import NavBar from "../navigation/NavBar.tsx";

const containerStyle: React.CSSProperties = {
    color: 'white',

    backgroundColor: 'black',
    height: `calc(100vh - ${40}px)`,
    padding: `${40}px 40px`,
    overflowY: 'auto',




}

function Leaderboards() {

    const leaderboard = useLeaderboard()

    if (!leaderboard) {
        return
    }

    return (


        <div style={containerStyle}>

            <Heading name={"Рейтинг"}></Heading>


            <div style={{ paddingTop: "20px" }}>

                {leaderboard.map((driver) => (
                    <LeaderboardElement
                        name={driver.driver.driver_profile.first_name}
                        car={driver.driver.car.brand}
                        balance={driver.totalIncomes}
                        last_name={driver.driver.driver_profile.last_name}
                    ></LeaderboardElement>
                ))}
            </div>
            <NavBar state="LEADERBOARDS"></NavBar>
        </div>

    )
}

export default Leaderboards