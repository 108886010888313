import React from 'react'
import { FilterBadge } from "./FilterBadge.tsx";
import useFilters from '../../../hooks/useFilters.tsx';


export function FilterList(props: { onFilterChange: (filter: string) => void, activeFilter: string }) {

    const style: React.CSSProperties = {
        display: 'flex',
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
        alignItems: 'center',
        minHeight: '40px',
        lineHeight: '1.5',
        padding: '20px 0'
    }

    const filters = useFilters()

    if (!filters) {
        return
    }

    return (
        <div style={style}>
            {filters.map((item, index) => (
                <FilterBadge
                    key={index}
                    name={item}
                    enabled={props.activeFilter === item}
                    onClick={props.onFilterChange}
                ></FilterBadge>
            ))}


        </div>
    );
}