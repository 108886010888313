import { useState, useEffect } from "react";
import { getActualDriverProfile, getDriverProfile } from "../services/driver.ts";
import { DriverProfile } from "../types/driverProfile.ts";

function useDriverProfile(driverId: string) {
    const [driver, setDriver] = useState<DriverProfile>();

    async function fetchDriverProfile() {
        const driverProfile = await getActualDriverProfile(driverId)
        setDriver(driverProfile)
    }

    useEffect(() => {
        fetchDriverProfile()
    }, []);

    return driver;
}


export default useDriverProfile