import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PhoneContext = createContext({ phone: '' });

export const usePhone = () => useContext(PhoneContext);

export const PhoneProvider = ({ children }) => {
    const location = useLocation();

    const [phone, setPhone] = useState(() => {

        return localStorage.getItem('phone') || '';
    });



    useEffect(() => {

        if (!phone) {
            const queryParams = new URLSearchParams(location.search);
            const phoneFromQuery = queryParams.get('phone');

            if (phoneFromQuery) {
                setPhone(phoneFromQuery);
                localStorage.setItem('phone', phoneFromQuery);
            }
        }
    }, [location.search, phone]);

    return (
        <PhoneContext.Provider value={{ phone }}>
            {children}
        </PhoneContext.Provider>
    );
};
