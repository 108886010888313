
export function Heading({ name }) {
    const styles = {
        container: {

            fontFamily: 'Rubik, sans-serif',
            fontSize: '30px',
            fontWeight: '700',
            color: '#ffffff',
        },

    };

    return (
        <div style={styles.container}>

            <span>{name}</span>
        </div>
    );
}